import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../sidebar/SidebarItem/SidebarItem';
import SpeakerList from '../../person/SpeakerList/SpeakerList';
import PersonMini from '../../person/PersonMini/PersonMini';
import SidebarItem from '../../sidebar/SidebarItem/SidebarItem';
import Include from '../../../../drupal/Include';
import BuyButton from "../BuyButton/BuyButton";
import DateTimeFormated from "../../../ui/DateTimeFormated/DateTimeFormated";

export default class Screening extends Component {

    render = () => {

        const dateTime = (
            <DateTimeFormated format="dddd, MMMM D [at] LT" date={this.props.screening.get('field_date_time')} />
        );

        let details = null;
        const venue = this.props.screening.get('field_space').get('field_venue');
        if (venue) {
            const url = venue.get('field_path');
            details = [
                <Link to={url}>{venue.get('title')}</Link>
            ];
        }


        const button = (<BuyButton screening={this.props.screening} />)

        let speakers = [];
        let speakerEntities = [];

        const introSpeakers = this.props.screening.get('field_intro_speakers');
        if(introSpeakers instanceof Array) {
            speakerEntities = speakerEntities.concat(introSpeakers);
        }

        const sponsorIntroSpeakers = this.props.screening.get('field_sponsor_intro_speakers');
        if(sponsorIntroSpeakers instanceof Array) {
            speakerEntities = speakerEntities.concat(sponsorIntroSpeakers);
        }

        const qaModerators = this.props.screening.get('field_q_a_moderators');
        if(qaModerators instanceof Array) {
            speakerEntities = speakerEntities.concat(qaModerators);
        }

        const qaPanelists = this.props.screening.get('field_q_a_panelists');
        if(qaPanelists instanceof Array) {
            speakerEntities = speakerEntities.concat(qaPanelists);
        }

        for(const speaker of speakerEntities) {
            if(speaker instanceof Include && speaker.data) {
                speakers.push(<PersonMini name={speaker.get('title')} position={speaker.get('field_position')} />);
            }
        }

        let qaMessage = null;
        if(this.props.screening.get('field_q_a_type')) {
            qaMessage = (
                <div className="qa-message subtitle">
                    This screening includes a {this.props.screening.get('field_q_a_type')} session.
                </div>
            );
        }

        let speakerList = null;
        if(speakers.length > 0) {
            speakerList = <SpeakerList speakers={speakers} />;
        }

        let extraContent = null;
        if(qaMessage || speakerList) {
            extraContent = (
                <div className="extra-content">
                    {qaMessage}
                    {speakerList}
                </div>
            );
        }

        return (
            <SidebarItem dateSort={this.props.screening.sortDate} subtitle={dateTime} details={details} button={button} extra={extraContent} />
        );
    }

}