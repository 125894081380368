import React, { Component } from "react";
import DateFilterOption from "./DateFilterOption";
import DateFilterWeek from "./DateFilterWeek";

import "./DateFilter.scss"
export default class DateFilter extends Component {

    state = {
        selectedOption: null,
    };

    render = () => {


        // Build list of results.  Starts with a Sunday and has full weeks.
        // Some will be placeholders and some will be options.
        // Then build flex grid of rows.
        // month wrapper around the weeks.
        // week row with seven options.
        // click handler on the month
        let optionMonth = [];
        let optionWeek = [];
        if (this.props.items[0]?.day > 0) {
            optionWeek = this.preFillWeek(this.props.items[0].day);
        }

        let lastDay = 0;
        for(const item of this.props.items) {

            if (optionWeek.length === 7) {
                // store the week away in the month
                optionMonth.push(<DateFilterWeek days={optionWeek} />)
                // Clear it out.
                optionWeek = [];
            }
            let selected = false;
            if(this.inSelectedArray(item.id, this.props.value)) {
                selected = true;
            }
            optionWeek.push(<DateFilterOption key={item.id} label={item.label} value={item.id} selected={selected} handleClick={this.props.handleClick}/>);
            lastDay = item.day;
        }
        if (lastDay < 6) {
            optionWeek = this.backFillWeek(lastDay, optionWeek);
        }
        // Might sill have a few options left.  If so, add to month.
        if (optionWeek.length > 0) {
            optionMonth.push(<DateFilterWeek days={optionWeek} />)
        }
        let dateReset = (
            <div className="reset-dates" onClick={this.handleReset} >Reset Dates</div>
        )

        let input = (
            <div className="df-input-wrapper" onChange={this.props.handleChange}>
                {dateReset}
                {optionMonth}
            </div>
        );


        return (
            <div className={`filter ${this.props.filterClasses}`}>
                {input}
            </div>
        );

    }

    getOptions = async (params) => {
        let options = [];

        let next = '';
        let hasNext = true;

        let response = await this.props.api.getEntities(this.props.optionsEntityType, this.props.optionsBundle, params);

        options = options.concat(response.data.data);

        if(response.data?.links?.next?.href) {
            next = response.data.links.next.href;
            
            while(hasNext) {
                response = await this.props.api.getResponseFromUrl(next);

                if(response.data?.links?.next?.href) {
                    next = response.data.links.next.href;
                } else {
                    hasNext = false;
                }

                options = options.concat(response.data.data);
            }
        }

        return options;

    }

    preFillWeek = (day) => {
        let result = [];
        for (let i = 0; i < 7; i++) {
            result.push(<DateFilterOption label="--" />);
            if (i === day) {
                break;
            }
        }
        return result;
    }

    backFillWeek = (lastDay, week) => {
        for (let i = lastDay + 1; i < 6; i++) {
            week.push(<DateFilterOption label="--" />)
        }
        return week;
    }

    handleReset = (e) => {
        this.props.handleReset();
    }


    inSelectedArray =(id, value) => {
        if (value instanceof Array) {
            return value.includes(id);
        }
        return false;
    }
}