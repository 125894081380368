import React, { Component } from "react";
import DateFilterOption from "./DateFilterOption";

export default class DateFilterWeek extends Component {



    render = () => {

        return (
            <div className="df-week">
                {this.props.days}
            </div>
        );

    }

}