import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Menu from './drupal/Menu';
import Api from './drupal/Api';
import Connection from './drupal/Connection';
import RouteResolver from './pages/RouteResolver/RouteResolver';
import SearchPage from './pages/SearchPage/SearchPage';
import Entity from './drupal/Entity';
import Header from './components/layout/Header/Header';
import Footer from './components/layout/Footer/Footer';
import SiteInfo from './drupal/SiteInfo';

class App extends Component {

  state = {
      menuItems: {},
      hasHero: false,
      drupal: null,
      api: null,
      outlet: null,
      isPage: true
  };

  constructor(props) {
      super(props);

      const drupal = new Connection();
      const api = new Api(drupal);

      this.state = {
          menuItems: {},
          hasHero: false,
          drupal: drupal,
          api: api
      };
  }


  componentDidMount = async () => {
      const options = {
          'sort[menu_name][path]': 'menu_name',
          'filter[menu][path]': 'menu_name',
          'filter[menu][value][1]': 'ajff-org-main-menu',
          'filter[menu][value][2]': 'programs',
          'filter[menu][value][3]': 'events',
          'filter[menu][value][4]': 'support-ajff',
          'filter[menu][value][5]': 'media',
          'filter[menu][value][6]': 'about-ajff',
          'filter[menu][operator]': 'IN',
          'sort[parent][path]': 'parent',
          'sort[weight][path]': 'weight',
          'sort[title][path]': 'title'
      };

      let isPage = true;
      if(window.location !== window.parent.location) {
        isPage = false;
      }

      const menuResponse = await this.state.api.getEntities('menu_link_content', 'menu_link_content', options)
      const drupalMenus = new Menu(menuResponse.data.data);

      const outlet = await this.state.api.getEntity('outlet', 'outlet', SiteInfo.getOutlet());

      this.setState({
        menuItems: drupalMenus.getMenuTrees(),
        outlet: outlet,
        isPage: isPage
      });

  }

  componentDidUpdate = (prevProps) => {
      if(this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }
  }

  hasHero = (e) => {
    let heroField = null;
    
    if(e && e.props.entity instanceof Entity) {
      heroField = e.props.entity.getField('field_hero');
    }

    if(heroField && heroField.hasOwnProperty('data') && heroField.data !== null) {
      this.setState({
        hasHero: true
      });
    } else {
      this.setState({
        hasHero: false
      });
    }
  }

  render() {
    let pageClass = 'downpage';

    if(this.props.location.pathname === '/') {
        pageClass = 'front';
    }

    let redirects = []; 
    if(this.state.outlet) {
      const items = this.state.outlet.get('field_redirects');
      for(const redirect of items) {
        redirects.push(<Redirect from={redirect.key} to={redirect.value} />);
      }
    } else {
      return (
        <>
        </>
      );
    }

    let header = null;
    let footer = null;

    if(this.state.isPage) {
        header = <Header outlet={this.state.outlet} menu={this.getMenu} hasHero={this.state.hasHero} />;
        footer = <Footer menu={this.getMenu} />;
    }

    return (
        <div className={ `page-wrapper ${pageClass}` }>
            <div className="page-top">
              {header}

              <Switch>
                {redirects}
                <Route path="/super-shopper/*" render={(props) => <RouteResolver {...props} outlet={this.state.outlet} api={this.state.api} drupal={this.state.drupal} hasHero={this.hasHero} ignoreUrlParts={[ '0' ]} />} />
                {/*<Route path="/super-screening/*" render={(props) => <RouteResolver {...props} outlet={this.state.outlet} api={this.state.api} drupal={this.state.drupal} hasHero={this.hasHero} ignoreUrlParts={[ '0' ]} />} />*/}
                <Route path="/search" exact render={(props) => <SearchPage {...props} api={this.state.api} drupal={this.state.drupal} />} />
                <Route path="/*" render={(props) => <RouteResolver {...props} outlet={this.state.outlet} api={this.state.api} drupal={this.state.drupal} hasHero={this.hasHero} />} />
              </Switch>
            </div>

            {footer}
        </div>
    );
  }

  getMenu = (menuName) => {
    if(this.state.menuItems.hasOwnProperty(menuName)) {
        return this.state.menuItems[menuName];
    }

    return [];
  }

}

export default withRouter(App);
