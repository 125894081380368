import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SpeakerList from '../../person/SpeakerList/SpeakerList';
import PersonMini from '../../person/PersonMini/PersonMini';
import Include from '../../../../drupal/Include';
import BuyButton from "../BuyButton/BuyButton";
import DateTimeFormated from "../../../ui/DateTimeFormated/DateTimeFormated";
import DetailedScreeningItem from "../screeningDetails/DetailedScreeningItem";

export default class DetailedScreening extends Component {

    render = () => {

        const dateTime = (
            <DateTimeFormated format="dddd, MMMM D [at] LT" date={this.props.screening.get('field_date_time')} />
        );

        let details = null;
        const venue = this.props.screening.get('field_space').get('field_venue');
        if (venue) {
            const url = venue.get('field_path');
            details = [
                <Link to={url}>{venue.get('title')}</Link>
            ];
        }

        // Film title
        let title = 'Unknown';
        let linkHref = null;
        const film = this.props.screening.get('field_feature_film');
        if (film instanceof Include) {
            title = film.get('title');
            linkHref = film.get('field_path');
        }



        const button = (<BuyButton screening={this.props.screening} />)

        return (
            <DetailedScreeningItem dateSort={this.props.screening.sortDate} link={linkHref} title={title} subtitle={dateTime} details={details} button={button} />
        );
    }

}