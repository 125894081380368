import React, { Component } from 'react';

import './HappeningCard.scss';
import DetailedScreening from "../DetailedScreening/DetailedScreening";

export default class HappeningCard extends Component {

    render = () => {

      let screenings = [];

      for(const screening of this.props.happenings) {
        screenings.push(<DetailedScreening key={screening.get('id')} screening={screening} />);
      }


      let title = null;
      if(this.props.showTitle && screenings.length > 0) {
        title = (
            <h3>Screenings</h3>
        );
      }

      let classes = null;
      if(this.props.isPageSection) {
        classes = 'page-section padded';
      }

      return (
          <div className={`screening-list ${classes}`}>
            {title}
            {screenings}
          </div>
      );

    }

}