import React, { Component } from 'react';

import './DetailedScreeningItem.css';

export default class DetailedScreeningItem extends Component {

    render = () => {
        let title = null;
        if (this.props.title && this.props.link) {
            title = (
                <h3><a href={this.props.link}>{this.props.title}</a></h3>
            );
        }

        let headline = null;
        if(this.props.headline) {
            headline = (
                <div>{this.props.headline}</div>
            );
        }

        let subtitle = null;
        if(this.props.subtitle) {
            subtitle = (
                <div className="subtitle">{this.props.subtitle}</div>
            );
        }

        let message = null;
        if(this.props.message) {
            message = (
                <div className="subtitle">{this.props.message}</div>
            );
        }

        let details = null;
        if(this.props.details && this.props.details.length > 0) {
            let detailItems = [];
            for(const detail of this.props.details) {
                detailItems.push(
                    <div className="detail">{detail}</div>
                );
            }

            details = (
                <div className="details">{detailItems}</div>
            );
        }

        let button = null;
        if(this.props.button) {
            button = (
                <div className="button-wrapper">{this.props.button}</div>
            );
        }

        let extra = null;
        if(this.props.extra) {
            extra = (
                <div className="extra">{this.props.extra}</div>
            );
        }

        let theme = 'standard';
        if(this.props.alternateTheme) {
            theme = 'alternate';
        }

        return (
            <div className={`sidebar-item ${theme}`}>
                <div className="details">
                    {title}

                    {headline}

                    {subtitle}

                    {message}

                    {details}

                    {button}

                    {extra}
                </div>

            </div>
        )
    }

}