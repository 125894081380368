import React, { Component } from 'react';
import Include from '../../drupal/Include';
import Filter from '../../components/ui/Filter/Filter';
import SuperShopperPagePlaceholder from './SuperShopperPagePlaceholder';

import './SuperShopperPage.css';
import HappeningCard
    from "../../components/content/happenings/HappeningCard/HappeningCard";
import DateFilter from "../../components/ui/DateFilter/DateFilter";

// Component is currently unused.  Built for POC.  Client declined to continue dev work.
export default class SuperScreeningPage extends Component {

    state = {
        allOfferings: [],
        filteredOfferings: [],
        filteredHappenings: [],
        allHappenings: [],
        allVenues: [],
        allSubjects: [],
        allGenres: [],
        allCountries: [],
        filteredVenues: [],
        filteredSubjects: [],
        filteredGenres: [],
        filteredCountries: [],
        allLanguages: [],
        filteredLanguages: [],
        exclusiveOptions: [],
        allDates: [],
        filteredDates: [],
        filters: {
            sort: 'asc',
        },
        loading: true
    };

    searchKeys = [
        'venue',
        'subject',
        'genre',
        'country',
        'availabilty',
        'language',
        'qas',
        'subtitle',
        'cc',
        'title',
        'sort',
    ];

    componentDidMount = async () => {

        const allHappenings = await this.getAllHappenings();
        // Need to add the sort Date to each happening.
        this.prepHappenings(allHappenings);




        const venues = this.getVenues(allHappenings);
        let exclusives= this.getExclusives();

        this.setState({
            allHappenings: allHappenings,
            filteredHappenings: allHappenings,
            allVenues: venues,
            filteredVenues: venues,
            exclusiveOptions: exclusives,
            filteredExclusives: exclusives,
            loading: false
        });

        this.refilter(this.getQueryOptions());

        // Need to know all the available dates.
        const dateOptions = this.getPossibleDateOptions(allHappenings);

        this.setState({
            allDates: dateOptions
        });
    }
    getQueryOptions = () => {
        let options = {};
        const query = new URLSearchParams(window.location.search);
        for(const qKey of this.searchKeys) {
            if (query.has(qKey)) {
                const val = query.get(qKey);
                switch (qKey) {
                    // Boolean need to be omitted when false.
                    case 'qas':
                    case 'subtitle':
                    case 'cc':
                        if (val === true) {
                            options[qKey] = val;
                        }
                        break;
                    default:
                        // Omit empty strings
                        if (!val) {
                            continue;
                        }
                        options[qKey] = val;
                }


            }
        }
        return options;
    }

    render = () => {

        if(this.state.loading) {
            return (
                <SuperShopperPagePlaceholder />
            );
        }

        let happeningCards = [];

        for(const happening of this.state.filteredHappenings) {

            happeningCards.push(<HappeningCard api={this.props.api} contentId={happening.get('id')} isFilm={false} showTitle={false} happenings={[happening]} />
            );


        }

        if(happeningCards.length === 0) {
            happeningCards = (
                <h3>We're sorry, but there are no films that match your current filters.</h3>
            )
        }

        return (
            <div className="super-shopper-page container">
                <h1>Venue Search</h1>
                <div className="filter-wrapper">
                    <h3>Filter for a Venue</h3>
                    <div><a href="/super-shopper/festival-series/2024-annual-festival">Film Search</a></div>
                    <div className="filters d-md-flex align-items-center">

                        <Filter key="venueFilter" filterName="venue"
                                filterLabel="Venue"
                                filterClasses="flex-shrink-1"
                                items={this.state.filteredVenues}
                                handleChange={this.handleSelect}
                                value={this.state.filters.venue}/>

                        <DateFilter key="datePicker" filterName="datePicker"
                                filterLabel="Pick a Date"
                                filterClasses="flex-shrink-1"
                                items={this.state.allDates}
                                handleClick={this.handleDateClick}
                                handleReset={this.handleDateReset}
                                value={this.state.filters.date}/>

                    </div>
                    <div className="clear-filters" onClick={this.clearFilters}
                         role="button">Clear all filters
                    </div>
                </div>

                <div className="super-shopper-page padded row">
                    <div className="col-12 search-results">
                        {happeningCards}
                    </div>
                </div>
            </div>
        );

    }

    handleDateClick = (val, selected) => {
        let filters = this.state.filters;
        let dateFilter = filters['date'];

        if (!Array.isArray(dateFilter)) {
            dateFilter = [];
        }

        if(val !== "null" && val !== "") {
            const inArray = dateFilter.includes(val);
            if (selected && !inArray) {
                dateFilter.push(val);
            }
            if (!selected && inArray) {
                dateFilter = this.removeElementFromFilter(dateFilter, val);
            }
            filters['date'] = dateFilter;
        }
        this.refilter(filters);
    }

    handleDateReset = () => {
        let filters = this.state.filters;
        delete(filters['date'])
        this.refilter(filters);
    }
    handleSelect = (e) => {
        let filters = this.state.filters;
        const val = e.currentTarget.value;
        const name = e.currentTarget.name;

        if(val !== "null" && val !== "") {
            filters[name] = val;
        } else {
            if (filters[name]) {
                delete filters[name];
            }
        }

        this.refilter(filters);
    }


    refilter = (filters) => {
        let filteredHappenings = [];
        let filteredOfferings = [];

        const filterKeys = Object.keys(filters);

        if (filterKeys.length === 0) {
            // No filters, so use all of them.
            this.setState({
                filteredHappenings: this.state.allHappenings,
                filteredOfferings: this.state.allOfferings,
                filters: filters
            });
            return;
        }

        // Looks like we are going to filter.
        for(const happening of this.state.allHappenings) {
            const offering = happening.get('field_offering');
            const featureFilm = offering.get('field_feature_film');
            if(featureFilm instanceof Include) {
                if( this.featureFilmInFilters(happening, featureFilm, filters)) {
                    filteredHappenings.push(happening);
                    if(!filteredOfferings.find(e => e.get('id') === offering.get('id'))) {
                        filteredOfferings.push(offering);
                    }
                }
            } else {
                // We need to look into the Program and see if it has any matches.
                const films = happening.get('field_offering').get('field_films');
                for(const film of films) {
                    if (this.featureFilmInFilters(happening, film, filters)) {
                        filteredHappenings.push(happening);
                        if(!filteredOfferings.find(e => e.get('id') === offering.get('id'))) {
                            filteredOfferings.push(offering);
                        }
                        // All we need is one film to match.
                        break;
                    }
                }
            }
        }

        switch (filters.sort) {
            case 'desc':
                filteredHappenings.sort((a, b) => a.sortDate < b.sortDate ? 1 : -1);

                break;
            default:
                filteredHappenings.sort((a, b) => a.sortDate > b.sortDate ? 1 : -1);
        }

        this.setState({
            filteredHappenings: filteredHappenings,
            filteredOfferings: filteredOfferings,
            filters: filters
        });

    }

    featureFilmInFilters = (happening, film, filters) => {
        for (const fKey in filters) {
            switch (fKey) {
                case 'date':
                    if (!this.inDateFilter(happening, filters.date)) {
                        return false;
                    }
                    break;
                case 'venue':
                    if (filters.venue !== happening.get('field_space').get('field_venue').get('id')) {
                        return false;
                    }
                    break;
                default:
            }
        }
        return true;
    }

    clearFilters = () => {
        const filters = {};

        this.refilter(filters);
    }

    getAllHappenings = async () => {
        let happenings = [];

        const params = {
            'filter[festival][path]': 'field_festival_series.id',
            'filter[festival][value]': this.props.entity.get('id'),
            'sort[date][path]': 'field_date_time',
            'sort[date][direction]': 'ASC'
        };

        let next = '';
        let hasNext = true;

        let screeningResponse = await this.props.api.getEntities('happening', 'screening', params);

        happenings = happenings.concat(screeningResponse.data.data);

        if(screeningResponse.data?.links?.next?.href) {
            next = screeningResponse.data.links.next.href;
            
            while(hasNext) {
                screeningResponse = await this.props.api.getResponseFromUrl(next);

                if(screeningResponse.data?.links?.next?.href) {
                    next = screeningResponse.data.links.next.href;
                } else {
                    hasNext = false;
                }

                happenings = happenings.concat(screeningResponse.data.data);
            }
        }

        next = '';
        hasNext = true;
        params['sort[date][path]'] = 'field_start_date';
        let virtualScreeningResponse = await this.props.api.getEntities('happening', 'virtual_screening', params);

        happenings = happenings.concat(virtualScreeningResponse.data.data);

        if(virtualScreeningResponse.data?.links?.next?.href) {
            next = virtualScreeningResponse.data.links.next.href;
            
            while(hasNext) {
                virtualScreeningResponse = await this.props.api.getResponseFromUrl(next);

                if(virtualScreeningResponse.data?.links?.next?.href) {
                    next = virtualScreeningResponse.data.links.next.href;
                } else {
                    hasNext = false;
                }

                happenings = happenings.concat(virtualScreeningResponse.data.data);
            }
        }

        return happenings;

    }

    getVenues = (happenings) => {
        let venues = [];
        for(const happening of happenings) {
            const id = happening.get('field_space').get('field_venue').get('id');
            if(venues.find(e => e.id === id)) {
                continue;
            } else {
                venues.push({
                    id: id,
                    label: happening.get('field_space').get('field_venue').get('title')
                });
            }
        }

        venues.sort((a, b) => (a.label > b.label) ? 1 : -1);

        return venues;
    }

    getExclusives = () => {
        return [
            {
                id : 'online',
                label: "Online Exclusive"
            },
            {
                id: 'in_person',
                label: "In Person Only"
            }
        ];
    }

    hasQA = (happening) => {

        let decision = false;

        if(happening.get('field_has_q_a') === true) {
            decision = true;
        }

        return decision;

    }

    hasCC = (film) => {

        let decision = false;

        if(film.get('field_has_cc') === true) {
            decision = true;
        }

        return decision;

    }

    hasSubtitles = (film) => {

        let decision = false;

        if(film.get('field_has_subtitles') === true) {
            decision = true;
        }

        return decision;

    }

    isHappeningAvailable = (happening, filter) => {
        let decision = false;

        switch(filter) {
            case 'in-theatre':
                if(happening.get('type') === 'happening--screening') {
                    decision = true;
                }
                break;
            case 'purchase':
                if(happening.get('field_sale_status') === 'on_sale') {
                    decision = true;
                }
                break;
            case 'watch-now':
                const datetime = new Date().toISOString();
                if(happening.get('type') === 'happening--virtual_screening' && happening.get('field_start_date') >= datetime && happening.get('field_end_date') <= datetime) {
                    decision = true;
                }
                break;
            default:
                decision = false;
        }

        return decision;
    }

    prepHappenings = (allHappenings) => {
        for (var i = 0; i < allHappenings.length; i++) {
            const timeStamp = allHappenings[i].get('field_date_time');
            const tempDate = new Date(timeStamp);
            allHappenings[i]["sortDate"] = (tempDate.getMonth() + 1) * 100 + tempDate.getDate();
            allHappenings[i]["sortDay"] = tempDate.getDay();
            allHappenings[i]["sortDateValue"] = tempDate.getDate();
        }
    }

    getPossibleDateOptions = (allHappenings) => {
        let options = [];
        let keys = [];
        for (var i = 0; i < allHappenings.length; i++) {
            // Exclude the virtual dates (1231) and any that are already an option.
            if (allHappenings[i].sortDate > 600 || keys.includes(allHappenings[i].sortDate)) {
                continue;
            }
            keys.push(allHappenings[i].sortDate);
            options.push ({
                'id': allHappenings[i].sortDate,
                'label': allHappenings[i].sortDateValue,
                'day' : allHappenings[i].sortDay
            });
        }
        return options;
    }

    inDateFilter = (happening, testDates) => {
        const date = happening.sortDate;

        // If there are no testDates, we are not filtering on dates.
        if (testDates.length === 0) {
            return true;
        }

        for (const testDay of testDates) {
            if (date === testDay) {
                return true;
            }
        }
        return false ;
    }

    removeElementFromFilter = (dateFilter, val) => {
        let $result = [];
        for (const date of dateFilter) {
            if (date === val) {
                continue;
            }
            $result.push(date);
        }
        return $result;
    }
}