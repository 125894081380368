import React, { Component } from "react";

export default class DateFilterOption extends Component {

    render = () => {
        let classes = "";
        if (this.props.selected) {
            classes = "selected";
        }

        return(
            <div className={`df-day ${classes}`} onClick={this.handleClick}>
                {this.props.label}
            </div>
        );

    }

    handleClick = (e) => {
        this.props.handleClick(this.props.value, !this.props.selected);
    }

}